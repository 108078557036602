<template>
  <div class="login">
    <div class="login-t"></div>
    <div class="login-c">
      <div class="box">
        <div class="h3">综合执法监管平台</div>
        <div class="item">
          <div class="item-l"><img src="./img/phone.png" alt="" /></div>
          <div class="item-r">
            <v-input
              v-model="form.phone"
              :maxlength="11"
              placeholder="请输入手机号"
              @blur="getUserRole"
            ></v-input>
          </div>
        </div>
        <div class="item">
          <div class="item-l"><img src="./img/lock.png" alt="" /></div>
          <div class="item-r">
            <v-input
              v-model="form.password"
              placeholder="默认手机号后六位"
              type="password"
            ></v-input>
          </div>
        </div>
        <div class="item">
          <div class="item-l"><img src="./img/people.png" alt="" /></div>
          <div class="item-r typeList" @click="selectType">
            <div class="txt">{{ userTypeMap[form.role] }}</div>
            <div class="img">
              <img v-if="isshow" src="./img/upArrow.png" alt="" />
              <img v-else src="./img/arrow.png" alt="" />
            </div>
            <div
              class="typeUlList"
              v-if="isshow"
              :style="{ bottom: -newUserType.length * 42 + 'px' }"
            >
              <div
                class="li"
                v-for="(item, index) in newUserType"
                :key="index"
                @click="selectLi(item)"
              >
                {{ item.text }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="login-b" @click="login">立即登录</div>
  </div>
</template>

<script>
import store from "@/store";
import { userTypeMap, userType } from "./map";
import { loginUrl, getUserRoleUrl } from "./api";
export default {
  name: "login",
  data() {
    return {
      userTypeMap,
      userType,
      newUserType: [],
      isshow: false,
      form: {
        phone: "",
        password: "",
        role: 1,
      },
    };
  },
  async created() {},
  mounted() {},
  methods: {
    async getUserRole() {
      this.newUserType = [];
      if (!this.form.phone) {
        return;
      }
      let params = {
        phone: this.form.phone,
      };
      let res = await this.$axios.get(`${getUserRoleUrl}`, { params });
      if (res.code == 200) {
        if (res.data) {
          let arr = res.data.split(",");
          arr.forEach((element) => {
            let obj = {
              value: element,
              text: userTypeMap[element],
            };
            this.newUserType.push(obj);
          });
        }
      } else {
        this.form.phone = "";
      }
    },
    async login() {
      if (this.form.phone == "") {
        this.$toast("请输入账号！");
        return;
      }
      if (this.form.password == "") {
        this.$toast("请输入密码！");
        return;
      }
      // const formData = new FormData();
      // formData.append("account", this.setAESCbcEncrypt(this.account));
      // formData.append("password", this.setAESCbcEncrypt(this.passWord));
      let res = await this.$axios.post(`${loginUrl}`, this.form);
      if (res.code == 200) {
        if (res.data && res.data.userId) {
          sessionStorage.setItem("userInfo", JSON.stringify(res.data));
          sessionStorage.setItem("role", this.form.role);
          this.updateStoreData(res.data);
          this.swithchPage();
        }
      }
    },
    swithchPage() {
      if (this.form.role == 1) {
        this.$router.push({
          name: "lawEnforcement-escalation",
        });
      }
      if (this.form.role == 2) {
        this.$router.push({
          name: "lawEnforcement-handle",
        });
      }
      if (this.form.role == 3) {
        this.$router.push({
          name: "lawEnforcement-manager",
        });
      }
    },
    updateStoreData(user) {
      store.commit("setUserId", user.userId);
      store.commit("setRole", user.role);
    },
    selectLi(item) {
      this.form.role = item.value;
      // this.isshow = false;
    },
    selectType() {
      this.isshow = !this.isshow;
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  height: 100%;
  background: url("./img/back.png") no-repeat;
  background-size: 100% 100%;
  .login-t {
    height: 650px;
    padding: 204px 36px 0;
    box-sizing: border-box;
    background: url("./img/loginBack.png") no-repeat;
    background-size: 100% 100%;
    .title {
      font-size: 48px;
      color: #3d7bfa;
      font-weight: 700;
      line-height: 66px;
      margin-bottom: 8px;
    }
    .h3 {
      font-size: 30px;
      color: #3d7bfa;
      font-weight: 700;
      line-height: 42px;
    }
  }
  .login-c {
    padding: 0 30px;
    margin: -200px auto;
    .box {
      height: 594px;
      background: #ffffff;
      border-radius: 30px;
      padding: 56px 30px 72px;
      box-sizing: border-box;
      .h3 {
        font-size: 48px;
        color: #3d7bfa;
        line-height: 66px;
        font-weight: 700;
        text-align: center;
        margin-bottom: 52px;
      }
      .item {
        display: flex;
        align-items: center;
        padding: 20px 24px;
        margin-bottom: 40px;
        border-bottom: 2px solid #f2f2f2;
        .item-l {
          width: 52px;
          height: 52px;
          margin-right: 24px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .item-r {
          flex: 1;
          height: 52px;
          /deep/ .van-cell {
            padding: 0;
          }
        }
        .typeList {
          position: relative;
          display: flex;
          justify-content: space-between;
          .txt {
            font-size: 30px;
            line-height: 52px;
          }
          .img {
            width: 20px;
            height: 8px;
            img {
              width: 100%;
              height: 100%;
            }
          }

          .typeUlList {
            position: absolute;
            bottom: -254px;
            left: -20px;
            width: 100%;
            background: #fff;
            box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.1);
            .li {
              padding: 14px 24px;
              font-size: 32px;
              line-height: 50px;
            }
          }
        }
      }
    }
  }
  .login-b {
    width: 574px;
    height: 80px;
    background: linear-gradient(135deg, #65a0f9 0%, #3067ff 100%);
    border-radius: 44px;
    margin: 260px auto 0;
    font-weight: 600;
    font-size: 30px;
    color: #ffffff;
    line-height: 80px;
    text-align: center;
  }
}
</style>
